<template>
    <wit-modal
        v-model="show"
        @input="emitInput"
        title="Publish new Version"
        variant="primary"
        disableDBlock
        size="lg"
        custom-class="publish-version-modal"
    >
        <wit-input-group v-model="previousVersion" type="text" input-label="Latest version" readonly input-col="10" />
        <wit-input-group
            v-model="$v.versionModalForm.major.$model"
            :model="$v.versionModalForm.major"
            type="number"
            input-label="Major"
            invalid="This field is required and needs to be a positive integer"
            valid="This field is valid"
            input-col="10"
            :step="step"
        />
        <wit-input-group
            v-model="$v.versionModalForm.minor.$model"
            :model="$v.versionModalForm.minor"
            type="number"
            input-label="Minor"
            invalid="This field is required and needs to be a positive integer"
            valid="This field is valid"
            input-col="10"
            :step="step"
        />
        <wit-input-group
            v-model="$v.versionModalForm.patch.$model"
            :model="$v.versionModalForm.patch"
            type="number"
            input-label="Patch"
            invalid="This field is required and needs to be a positive integer"
            valid="This field is valid"
            input-col="10"
            :step="step"
        />
        <wit-input-group
            v-model="$v.versionModalForm.stage.$model"
            :model="$v.versionModalForm.stage"
            type="select"
            :options="stageOptions"
            input-label="Stage (optional)"
            input-col="10"
        />
        <wit-input-group
            v-model="$v.versionModalForm.stageNumber.$model"
            :model="$v.versionModalForm.stageNumber"
            type="number"
            input-label="Stage number (optional)"
            input-col="10"
        />
        <hr />
        <wit-input-group
            v-model="$v.migrationGuide.text.$model"
            :model="$v.migrationGuide.text"
            type="textarea"
            :min-rows="3"
            input-label="Migration guide (optional)"
            input-col="10"
        />
        <wit-input-group
            v-model="$v.migrationGuide.url.$model"
            :model="$v.migrationGuide.url"
            type="text"
            :min-rows="3"
            input-label="Migration guide url (optional)"
            input-col="10"
        />

        <b-row>
            <b-col>
                <Feedback
                    v-if="customQueryError"
                    :state="customQueryError.state"
                    :invalid="customQueryError.message"
                ></Feedback>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <Feedback
                    :state="step.checked ? !$v.$invalid : null"
                    invalid="You need to fill in required fields"
                    valid="This step is complete"
                ></Feedback>
                <b-btn variant="primary" @click="publish" :disabled="step.checked && $v.$invalid">
                    <i class="icon-check"></i>
                    <span> Publish</span>
                </b-btn>
            </b-col>
        </b-row>
    </wit-modal>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {
        Feedback,
        WitInputGroup,
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        previousVersion: {
            type: String,
        },
        customQueryError: {
            type: Object,
        },
    },
    mixins: [validationMixin],
    validations: {
        versionModalForm: {
            major: {required, valid: value => value >= 0},
            minor: {required, valid: value => value >= 0},
            patch: {required, valid: value => value >= 0},
            stage: {},
            stageNumber: {},
        },
        migrationGuide: {
            text: {},
            url: {},
        },
    },
    data() {
        return {
            show: false,
            versionModalForm: {
                major: null,
                minor: null,
                patch: null,
                stage: null,
                stageNumber: '',
            },
            migrationGuide: {
                text: '',
                url: '',
            },
            releaseNotes: [],
            stageOptions: ['alfa', 'beta', 'rc'],
            step: {
                checked: false,
            },
        }
    },
    watch: {
        value() {
            this.show = this.value
        },
        previousVersion() {
            this.setVersion()
        },
    },
    created() {
        this.show = this.value

        this.setVersion()
    },
    methods: {
        emitInput() {
            this.$emit('input', this.show)
        },

        setVersion() {
            if (this.previousVersion) {
                const [major, minor, _patch] = this.previousVersion.split('.')
                const [patch] = _patch.split('-')

                this.versionModalForm.major = Number(major)
                this.versionModalForm.minor = Number(minor)
                this.versionModalForm.patch = Number(patch) + 1
            }
        },

        publish() {
            this.step.checked = true

            if (!this.$v.$invalid) {
                const version = Object.keys(this.versionModalForm).reduce(
                    (acc, curr) =>
                        this.versionModalForm[curr] || this.versionModalForm[curr] === 0
                            ? {...acc, [curr]: this.versionModalForm[curr]}
                            : acc,
                    {}
                )

                const migrationGuide = Object.keys(this.migrationGuide).reduce(
                    (acc, curr) => (this.migrationGuide[curr] ? {...acc, [curr]: this.migrationGuide[curr]} : acc),
                    {}
                )

                this.$emit('publish', {
                    version,
                    migrationGuide: Object.keys(migrationGuide).length > 0 ? migrationGuide : undefined,
                })
            }
        },
    },
}
</script>

<style lang="scss">
.publish-version-modal {
    legend {
        font-weight: 600;
        font-size: 0.87rem;
    }
}
</style>
